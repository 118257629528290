export interface DefaultEnvironment {
	environmentName: string;
	apiSuffix: string;
	trovataApi: string;
	beta: boolean;
	production: boolean;
	development: boolean;
	testing: boolean;
	productionToggles: boolean;
	stripe?: string;
	stripePublishableKey?: string;
	plaidRedirectUri: string;
	oauthRedirectUri: string;
}

export const DEFAULT_ENVIRONMENT_CONFIG: DefaultEnvironment = {
	environmentName: 'local',
	apiSuffix: '',
	production: false,
	beta: false,
	development: false,
	testing: false,
	productionToggles: false,
	trovataApi: 'https://widget-api-dev.trovata.io',
	// plaidRedirectUri: 'http://localhost:4200/plaid-oauth', // use this url when plaid is in sandbox mode
	plaidRedirectUri: 'https://app.trovata.io/plaid-oauth',
	oauthRedirectUri: 'https://app.trovata.io/home/connections/success',
};
